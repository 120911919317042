// Buttons dark styles of the app 

@include color-mode(dark) {
  .btn-icon {
    background: $codgray;
    border-color: $border-color-dark;

    svg {
      color: $white;
    }
  }

  .btn {

    &.btn-primary {
      background-color: $burntsienna;
      border-color: $burntsienna;
      color: $black;

      &:hover,
      &.show {
        background-color: lighten($burntsienna, 5%);
        border-color: lighten($burntsienna, 5%);
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(237, 98, 81, 0.3);
      }
    }

    &.btn-primary:not(:disabled):not(.disabled):active {
      background-color: lighten($burntsienna, 5%);
      border-color: lighten($burntsienna, 5%);
    }

    &.btn-primary {
      &:disabled,
      &.disabled {
        background-color: $burntsienna;
        border-color: $burntsienna;
      }
    }

    &.btn-primary:not(:disabled):not(.disabled):active:focus,
    &.btn.btn-primary:focus-visible {
      box-shadow: 0 0 0 0.2rem rgba(237, 98, 81, 0.3);
    }


    &.btn-outline-primary {
      border-color: $burntsienna;
      color: $burntsienna;

      &:hover,
      &.show {
        background-color: $burntsienna;
        border-color: $burntsienna;
        color: $black;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(237, 98, 81, 0.3);
      }
    }

    &.btn-outline-primary:not(:disabled):not(.disabled):active {
      background-color: lighten($burntsienna, 5%);
      border-color: lighten($burntsienna, 5%);
      color: $black;
    }

    &.btn-outline-primary {
      &:disabled,
      &.disabled {
        background-color: $burntsienna;
        border-color: $burntsienna;
      }
    }

    &.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
    &.btn.btn-outline-primary:focus-visible {
      box-shadow: 0 0 0 0.2rem rgba(237, 98, 81, 0.3);
    }






    &.btn-link {
      color: $secondary-dark;
      border-bottom-color: $secondary-dark;
      box-shadow: none;

      &:hover {
        border-bottom-color: transparent;
      }

      &:active {
        color: $secondary-dark;
      }

      &:focus {
        box-shadow: none;
      }
    }

    &.btn-link:not(:disabled):not(.disabled):active {
      border-bottom-color: $oslogray;
    }

    &.btn-link:not(:disabled):not(.disabled):active:focus,
    &.btn.btn-link:focus-visible {
      box-shadow: none;
      border-bottom-color: $oslogray;
    }
  }

}