// Cards dark styles of the app 

@include color-mode(dark) {
  .card, 
  .slide-card {
    background-color: $codgray;
    border-color: $codgray;
  }
  .slide-card {
    background-color: $body-bg-dark;
    &:hover {
      border-color: $gray-700;
    }
    .card-text {
      .checkbox {
        border-color: $border-color-dark;
        background-color: $body-bg-dark;
        &.checked {
          background-color: $burntsienna;
          border-color: $burntsienna;
          svg {
            color: $white;
          }
        }
      }
    }
  }
}
