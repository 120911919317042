/*! Main styles for Edly Panel */


// Core

@import './custom/variables.scss';
@import './custom/mixins';
@import './custom/fonts';

// Bootstrap

@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/bootstrap-utilities.scss';
@import '~bootstrap/scss/bootstrap-reboot.scss';
@import '~bootstrap/scss/bootstrap-grid.scss';

// Custom includes

@import './custom/components/loader';
@import './custom/components/sidebar';
@import './custom/components/navbars';
@import './custom/components/forms'; 
@import './custom/components/buttons';
@import './custom/components/slider';
@import './custom/components/card';
@import './custom/components/table';
@import './custom/components/pagination';
@import './custom/components/tooltip';
@import './custom/components/modal';
@import './custom/components/footer';

@import './custom/components/dark-loader';
@import './custom/components/dark-sidebar';
@import './custom/components/dark-navbars';
@import './custom/components/dark-buttons';
@import './custom/components/dark-card';
@import './custom/components/dark-forms';
@import './custom/components/dark-modal';
@import './custom/components/dark-table';
@import './custom/components/dark-pagination';
@import './custom/components/dark-tooltip';
@import './custom/dark-common'; 

@import './custom/common'; 

