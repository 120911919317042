// Form styles of the app 

.sites-search-holder {
  position: relative;

  .ico-search {
    @include position(absolute, 50%, null, null, 10px);
    @include size(16px, 16px);
    transform: translateY(-50%);
  }

  .form-control {
    padding-left: 2.5rem;
  }
}

.form-control,
.form-select, 
.form-check-input {
  box-shadow: $box-shadow;
}

.maus-filter {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  .small-drop {
    .collapse {
      min-width: 240px;
    }
  }

  .card {
    border: none;
    box-shadow: none;
  }

  .card-header {
    display: inline-flex;
    @include d-inline-flex(center, space-between, null, null);
    border: 1px solid $border-color;
    box-shadow: $box-shadow;
    background-color: $white;
    padding: 0.438rem 0.938rem;
    border-radius: $border-radius;
    min-width: 7.5rem;
    font-size: 0.75rem;
  
    &.open {
      border-color: $secondary;

      svg {
        color: $secondary;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .collapse {
    position: absolute;
    z-index: 3;
    left: 0;
    top: 100%;
    background-color: $white;
    box-shadow: $box-shadow;
    border: 1px solid $border-color;
    border-radius: $border-radius;

    .card-body {

      .date-range-container {
        background: $white;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        border: 1px solid $border-color;
        display: flex;
        flex-wrap: wrap;
        margin-top: -40px;
        z-index: 2;
        position: relative;
        padding: 0.75rem 1.25rem 0;
        min-height: 18.75rem;
        >div {
          min-height: 15.625rem;
        }

        .date-range-header {
          flex: 1 0 100%;
          margin-bottom: 0.5rem;
          min-height: 0;
          padding: 0 0.366rem;

          svg {
            display: inline-block;
            vertical-align: middle;
            margin: -2px 6px 0 0;
            color: $tuna;
          }

        }

        .date-range-divider {
          padding: 1.75rem 1.563rem;
          position: relative;
          @include media-breakpoint-down(lg) {
            padding: 0.963rem 0;
            text-align: center;
          }

          span {
            background: $white;
            color: $oslogray;
            position: relative;
            z-index: 2;
            padding: 0.5rem 0;
            font-size: 0.875rem;
          }

          &:before {
            content: '';
            background: $gray-300;
            @include position(absolute,0,0,1rem,0);
            width: 0.063rem;
            margin: auto;
            @include media-breakpoint-down(lg) {
              width: 100%;
              height: 0.063rem;
              background-color: transparent;
            }
          }
        }
      }

      .maus-filter-footer {
        padding: 0.625rem 0 0;
        text-align: right;

      }
    }

    &.show {
      display: block;
    }
  }
}

.datepickers-holder {
  display: flex;
  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }
}

.react-datepicker {
  border: 0;

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    top: 4px;
  }

  .react-datepicker__navigation--previous {
    left: 0;
  }

  .react-datepicker__navigation--next {
    right: 0;
  }

  .react-datepicker__day--keyboard-selected, 
  .react-datepicker__month-text--keyboard-selected, 
  .react-datepicker__quarter-text--keyboard-selected, 
  .react-datepicker__year-text--keyboard-selected {
    background-color: $primary;
  }

  .react-datepicker__month-container {

    .react-datepicker__header {
      border: 0;
      padding: 0;
      background: transparent;

      .react-datepicker__current-month {
        font-weight: normal;
        font-size: 0.875rem;
        margin-bottom: 0.188rem;
      }

      .react-datepicker__day-names {
        margin-bottom: 6px;

        .react-datepicker__day-name {
          @include size(1.625rem,1.25rem);
          color: $oslogray;
        }
      }
    }

    .react-datepicker__month {
      margin: 0;

      .react-datepicker__week {
        border-radius: 1.875rem;
        overflow: hidden;

        .react-datepicker__day {
          border-radius: 2.5rem;
          @include size(1.625rem, 1.625rem);
          line-height: 1.5rem;
          border: 1px solid transparent;

          &:hover {
            background: $provincialpink !important;
          }

          &.react-datepicker__day--in-selecting-range {
            background: transparent;
            color: $dovegray;
          }

          &.react-datepicker__day--in-range {
            background: $provincialpink;
            color: $dovegray;
          }

          &.react-datepicker__day--selected {
            background: $primary;
            border-color: $primary;
            color: $white;

            &:hover {
              background: $primary !important;
            }
          }
        }
      }
    }
  }
}

.custom-filters {
  @include d-inline-flex(center,null,null,null);
  @include media-breakpoint-down(sm) {
    width: 100%;
    flex-direction: column;
  }
  .maus-filter-container {
    margin-left: 0.625rem;
    @include media-breakpoint-down(sm) {
      margin-left: 0;
      width: 100%;
      &:last-child {
        margin-top: 0.938rem;
      }
      .maus-filter {
        width: 100%;
      }
    }
  }
}

.custom-control {
  @include d-flex(center,space-between,null,null);
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.pagination-container {
  @include d-flex(center,null,null,null);
}

.pagination-dropdown {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-left: 0.625rem;

  .card {
    border: none;
    box-shadow: none;
  }

  .card-header {
    display: inline-block;
    border: 1px solid $border-color;
    background-color: $white;
    padding: 0.438rem 0.638rem;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    min-width: 3.9rem;
    font-size: 0.75rem;
  
    svg {
      margin: 0;
      float: right;
    }

    &.open {
      border-color: $primary;

      svg {
        color: $primary;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .collapse {
    position: absolute;
    z-index: 3;
    left: 0;
    top: 100%;
    background-color: $white;
    border: 1px solid $border-color;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
  
    .card-body {
      padding: 1rem;
      ul {
        @extend %listreset;
        margin: 0;

        li {
          margin: 0;
          border-style: none;
 
        }
      }

    }

    &.show {
      display: block;
    }
  }
}

.csv-main {
  margin-top: 0.938rem;
  padding: 2.188rem 0.938rem;
  border: 0.125rem dashed $border-color;
  border-radius: $border-radius;
 
  .csv-img-container {
    text-align: center;
    max-width: 5rem;
    margin: 0 auto;
    @include media-breakpoint-up(lg) {
      max-width: 13.75rem;
    }
    .csv-img {
      margin: 0.938rem auto 1.875rem;
      max-width: 100%;
    }
  }

  .fa-check,
  .fa-times {
    float: right;
    @include size(1.5rem, 1.5rem);
    border-radius: 50%;
    font-size: 0.688rem;
    margin-top: -1px;
    padding-top: 0.188rem;
    text-align: center;
  }

  .fa-times {

    &:hover {
      cursor: pointer;
    }
  }
}

.csv-note, 
.csv-template, 
.csv-text {
  text-align: center;
}

.csv-button {
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  color: $secondary;
  font-weight: 600;
}

.csv-help {
  text-align: center;
  color: $oslogray;
  font-size: 0.875rem;
  margin: 0 auto;
  max-width: 23.75rem;

  .csv-note {
    font-weight: 600;
  }

  a {
    text-decoration: underline;
    margin-left: 0.313rem;
    margin-right: 0.313rem;
  }
}

.custom-file-field {

  [type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
  }

  [type="file"] + label {
    border: none;
    color: $secondary;
    cursor: pointer;
    display: inline-block;
    outline: none;
    position: relative;
    transition: all 0.3s;
    vertical-align: middle;
    text-decoration: underline;
    font-size: 1rem;
    
    &:hover {
      text-decoration: none;
    }
  }
}

.upload-box {
  @include size(11.25rem);
  @include d-inline-flex(center,center,null,null);
  border: 1px solid $border-color;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  img {
    max-width: 9.375rem;
    max-height: 9.375rem;
    object-fit: contain;
  }
}

.file-delete-hold {
  background: $orange-white;
  @include d-flex(center,center,null,null);
  @include size(11.25rem, auto);
  border-radius: 0 0 4px 4px;
  padding: 0.313rem;
  margin-top: -0.313rem;
  svg {
    @include size(1.25rem, auto);
    display: inline-block;
    color: $red;
    &:hover {
      cursor: pointer;
    }
  }
}

.switch-holder {
  text-align: right;
  display: flex;
  align-items: center;
  label {
      margin:0 0 0 0.313rem;
      font-size: 0.75rem;
      min-width: 1.125rem;
      text-align: left;
  }
}

.lock-fields {
  position: relative;
  svg {
    @include position(absolute,50%, 20px, null, null);
    transform: translateY(-50%);
  }
}

.light-switch {
  border: 1px solid $gray-300;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.02);
}

.custom-file {
  position: relative;
  transition: $transition-base;

  .form-control {
    @include position(absolute,0,null,null,0);
    @include size(100%,100%);
    opacity: 0;
    border: 0;
    background: none;
    font-size: 0;
    text-indent: -3.125;
    cursor: not-allowed;
    transition: $transition-base;

    &:not(:disabled) {
      cursor: pointer;

      &:hover,
      &:focus,
      &:active {
        ~ .custom-file-label {
          background: $white;
        }
      }
    }
  }

  .custom-file-label {
    background: rgba($secondary, 0.05);
    color: $secondary;
    border: 0.063rem dashed;
    padding: 0.625rem 0.625rem 0.75rem;
    display: block;
    text-align: center;
    font-weight: 400;
    border-radius: $border-radius;
    cursor: pointer;
    transition: $transition-base;
    box-shadow: $box-shadow;
    font-size: 0.875rem;
  }
}

.app-block {
  .dropdown {
    position: relative;
    border: 1px solid $border-color;
    width: 100%;
    border-radius: $border-radius;
    background-color: $white;
    box-shadow: $box-shadow;
    .form-control {
      margin-bottom: 10px;
      padding: 4px 11px;
      height: auto;
    }
  }
  .dropdown-menu {
    width: 100%;
    padding: 20px;
    .list-unstyled {
      padding: 0;
      margin-bottom: 0;
      max-height: 180px;
      overflow-x: hidden;
      overflow-y: auto;
      .dropdown-item {
        &:first-child {
          @include media-breakpoint-down(md) { 
            .slide-card {
              .card-text {
                h6,
                .site-name {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
      .dropdown-item {
        border-radius: 4px;
        margin: 0.063rem 0;
        padding: 0.313rem 0;
        @include media-breakpoint-down(md) { 
          padding: 1rem 0;
          border-top: 1px solid $border-color;
        }
        &:hover {
          background: rgba(60, 64, 67, 0.08);
        }
        .slide-card {
          border-radius: 0;
          border: none;
          background: no-repeat;
          padding: 0 10px;
          margin: 0.063rem 0;
          min-height: inherit;
          border: none;
          border-radius: 0;
          box-shadow: none  ;
          .site-name {
            margin-bottom: 0;
            display: flex;
            align-items: center;
            min-height: inherit;
            @include media-breakpoint-down(md) {
              margin-bottom: 15px;
            }
          }
          .card-text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            width: 100%;
            h6 {
              text-transform: capitalize;
              margin-bottom: 0;
            }
            @include media-breakpoint-down(md) {
              flex-direction: column;
              align-items: start;
            }
          }
        }
      }
    }
  }
}

.chips-close {
  @include size(18px);
}

.site-chips {
  display: inline-flex;
  align-items: self-start;
  border-radius: 2.5rem;
  background-color: rgba($color: $black, $alpha: 0.1) ;
  padding: 0.25rem 0.938rem;
  text-transform: capitalize;
  font-size: 0.875rem;
  margin: 0.313rem 0.5rem 0 0;
  .chips-close {
    margin-left: 0.313rem;
  }
  svg {
    @include size(1.125rem);
    &:hover {
      cursor: pointer;
    }
  }
}

.sites-dropdown {
  width: calc(100% - 2.5rem);
  min-height: 3.125rem;
  padding: 0.313rem 0.75rem 0.438rem;
  + span {
    @include size(1.563rem);
    margin: 0.813rem 0.625rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
  }
}

.dropdown-menu {
  .slide-card {

    .card-text {
      display: flex;
      flex-direction: column;

      .checkbox {
        @include size(20px, 20px);
        display: flex;
        margin-right: 1rem;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1.5px solid $border-color;
        background-color: $white;
        transition: all 0.3s ease;

        &.checked {
          svg {
            color: $primary;
            opacity: 1;
          }
        }

        svg {
          opacity: 0;
        }
      }
      h6 {
        min-height: inherit;
      }
    }
  }
}

.checkbox_Container {
  display: flex;
  justify-content: flex-start;
  input[type='checkbox'] {
    @include size(2.625rem, 1.375rem);
    position: relative;
    margin-left: 1.25rem;
    -webkit-appearance: none;
    appearance: none;
    background: $white;
    border: 1px solid $border-color;
    box-shadow: $box-shadow;
    outline: none;
    border-radius: 2rem;
    cursor: pointer;
    &:before {
      content: '';
      @include position(absolute,1px,auto,auto,2px);
      @include size(1.125rem, 1.125rem);
      border-radius: 50%;
      background: $gray-200;
      transition: $transition-base;
    }
    &:checked:before {
      transform: translateX(100%);
      background: $white;
    }
    &:checked {
      background: $secondary;
      border: 1px solid $secondary;
    }
  }
}

.radio-holder {
  margin: 5px 4px;
  display: inline-flex;
  label {
    cursor: pointer;
    @include size(40px, 40px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    &:after {
      content: "";
      border-radius: 100%;
      @include size(100%, 100%);
      @include position(absolute,0,null,null,0);
      z-index: 1;
      background: $gray-300;
    }
    span {
      position: relative;
      z-index: 2;
    }
  }
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
  }
  [type="radio"]:checked + label {
    color: $white;
  }
  [type="radio"]:checked + label:after {
    background:$primary;
  }
}

body {
  .react-select__multi-value {
    background-color: $wildsand;
  }
  .react-select__control  {
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    border-color: $border-color;
    &:hover {
      border-color: $border-color;
    }
  }
  .react-select__multi-value__remove {
    &:hover {
      background-color: $wildsand;
      color: $gray-900;
    }
  }
  .react-select__option {
    background-color: transparent;
    &:hover,
    &:focus,
    &:active {
      background-color: $gray-100;
      cursor: pointer;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
