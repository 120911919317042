// Tooltip dark styles of the app 

@include color-mode(dark) {
  .tool-tip {
  
    .fa-question-circle {
      color: $mineshaft;
    }

    .tip-content {
      background: $gray-100;
      border: 1px solid $gray-100;
      color: $black;
  
      &:before,
      &:after {
        border-color: transparent transparent $white;
      }

      &:after {
        border-color: transparent transparent $white;
      }
    }

  }
}

