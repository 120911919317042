// Loading dark styles of the app 

@include color-mode(dark) {
  .loading {
  
    &.preload {
      background: $body-bg-dark;
    }
  
    .loading-dots {
  
      & > li {
        background-color: $white;
  
       }
    }
  }
  
}
