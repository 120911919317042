.loading {
  @include size(100%, 100%);
  position: relative;
  overflow: hidden;
  margin-top: 1.875rem;

  &.preload {
    background: $white;
    @include position(fixed,0,0,0,0);
    @include d-flex(center, center, null, null);
    z-index: 9999;
    margin: auto;
  }

  &.section-loader {
    height: auto;
    z-index: 1;
  }

  &.center-align {
    margin: auto;
  }

  .loading-dots {
    @include d-flex(center, center, null, null);
    @extend %listreset;
    text-align: center;
    min-height: 1.25rem;
   
    & > li {
      @include size(0.875rem, 0.875rem);
      display: inline-block;
      margin: 0.188rem;
      padding: 0;
      animation: loading-bouncedelay 1.4s infinite ease-in-out both;
      border-radius: 50%;
      background-color: $primary;

      &:first-child {
        animation-delay: -0.32s;
      }

      &:nth-child(2) {
        animation-delay: -0.16s;
      }
    }
  }
}

@keyframes loading-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}
