.feature-overview {

    .form-head h4 {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .form-head h4 span svg:hover {
        cursor: pointer;
        color: rgba(221, 31, 37, .7);
    }

    .feature-cta {
        padding: 0px 2rem;
        display: flex;
        align-items: center;
        justify-content: right;
    }
}
