.body-text {
  display: block;
  margin: 10px auto;
  font-size: 22px;
}

.body-text-small {
  display: block;
  margin-top: 10px;
  font-size: small;
}
