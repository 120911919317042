// "Common dark app styles"

@include color-mode(dark) {

  body { 
    background-color: $black;
    background-image: none;
  }

  .user-email {
    color: $white;
  }

  .organization-display {
    background-color: $codgray;
    border-bottom: 1px solid $border-color-dark;
    margin-bottom: -5px;
  }

  .alert {
    &.alert-danger {
      color: $burntsienna;
      border-color: $burntsienna;
      background-color: $gondola;
      .alert-link {
        color: $burntsienna;
      }
    }
  }
  
  .card-area {
    background-color: rgba(30,30,30,60%);
    border-color: transparent;
  }
  
  .main-content-holder {
    background: rgba(30,30,30,60%);
    border: 0.063rem solid rgba(30,30,30,60%);
  }

  .sites-head {
    .sites-controls {
      .btn-icon {
        &.icon-selected {
          border-color: $secondary-dark;
          svg {
            color: $white;
          }
        }
      }
    }
  }

  .addons-holder {
    background-color: rgba($color: #fff, $alpha: 0.03);
  }

  .list-group {
    button.list-group-item {
      background-color: $codgray;
      border-color: $border-color-dark;
      &:hover,
      &:focus,
      &:active {
        background-color: $black;
      }
      &.active {
        background-color: $burntsienna;
        border-color: $border-color-dark;
        color: $black;
        font-weight: bold;
      }
    }
  }

  .text-error {
    color: $burntsienna;
  }

  .paid-label {
    color: $secondary-dark;
    border-color: $secondary-dark;
  }

  .theme-card {
    .slick-list {
      .theme-slide {
        .image-post {
          .ico-view {
            background-color: $white;
            svg {
              stroke: $black;
            }
          }
        }
      }
    }
  }

  .link {
    color: $secondary-dark;
  }

  .active-status {
    background-color: $white;
    color: $black;
  }

  .stats-block,
  .quick-stats {
    .number {
      border-color: $border-color-dark;
      
      &.success {
        color: $white;
        background: transparent;
      }

      &.fails {
        color: $white;
        background: transparent;
      }

      &.zero {
        color: $white;
        background: transparent;
      }
    }
  }

  p {
    a {
      color: $white;
    }
  }

  .rs-slider-progress-bar {
    background-color: $secondary-dark;
  }

  .rs-tooltip {
    background-color: $white;
    color: $black;
    &:after {
      border-top-color: $white;
    }
  }

  .rs-slider-handle:before {
    border-color: $secondary-dark;
  }

  .slide-card {
    border-color: $codgray;
    background-color: $codgray;
    &.card-selected {
      border-color: $secondary-dark;
    }
    &:hover {
      border-color: $border-color-dark;
    }
  }

  .csv-button {
    color: $secondary-dark;
  }

  .user-filter-list {
    > li {
      color: $white;
    }
  }

  iframe {
    color-scheme: light;
  }

  .color-secondary {
    color: $secondary-dark;
  }

  .edly-go-banner {
    
    .img-holder {
      background-image: url(/assets/images/edly-Go-logo-white.svg);
    }
    
  }

  .edly-go-banner {
    .img-holder {
      border-color: $white;
    }
  }

  .has-border-bottom {
    border-color: $border-color-dark;
  }

  .user-filter-holder {
    @include media-breakpoint-down(xl) {
      .navbar-collapse {
        background-color: $body-bg-dark;
        border: 1px solid $border-color-dark;
      }
    }
   }

  .theme-card {
    .slick-list {
      .theme-slide {
        border-color: $border-color-dark;
      }
    }
  }

  .switch-billing-banner {
    background: $body-bg-dark;
    border-color: $border-color-dark;
    h5 {
      span {
        color: $white;
      }
    }
  }

  .main-content {
    &:after {
      background: $body-bg-dark;;
      border-color: $border-color-dark;
    }
  }

  .course-analytics-list {
    li {
      &.head-list-item {
        border-color: $border-color-dark;
      }
    }
  }

  .themes-table {
    .theme-frame {
      border-color: $border-color-dark;
    }
  }

  .upload-box,
  .switch-row {
    border-color: $border-color-dark;
  }

  .theme-color-block {
    .color-block {
      background: $body-bg-dark;;
      border-color: $border-color-dark;
    }
  }

  .slick-arrow {
    stroke: $white;
  }

  .check-list {
    li {
      svg {
        stroke: $white;
      }
    }
  }

  .user-guide {

    .demo-container {
  
      #learner_guide,
      #Instructors_guide {
        border-color: $border-color-dark;
       
        &:hover {
          background-color: $mineshaft;
          border-color: $border-color-dark;
  
          svg {
            stroke: $white;
          }
        }
      }
    }
  }

  .courses-analytics {
    .course-details {
      border-color: $codgray;
      background-color: $codgray;
  
      .course-meta {
  
        .course-code {
          color: $white;
        }

        .course-name {
          color: $secondary-dark;
        }

      }
    }
    .course-instructors,
    .course-dated {
      display: block;

      span {
        color: $white !important;
      }
    }
    .course-analytics-values {
      border-color: $border-color-dark;
      color: $white;
      > div {
       
        span {
          border-color: $border-color-dark;
        }
      }
    }
  }

  .dns-card {
    border-bottom: 0.063rem solid $border-color-dark;
    &:after, 
    &:before {
      content: "";
      background: $border-color-dark;
    }
  }

  .sorter {
    &:before,
    &:after {
      border-color: transparent transparent $white;;
    }

    &:after {
      border-color: $white transparent transparent;
    }
  }
  
  .courses-analytics {
    .course-details {
  
      .course-analytics-values {
        border-top: 1px solid transparent;
        color: $white;
        @include media-breakpoint-up(xl) {
          border-left: 1px solid $border-color-dark;
        }
        @include media-breakpoint-up(xxl) {
          border-left: 1px solid $border-color-dark;
        }
  
        > div {
  
          span {
            border-bottom: 0.063rem solid $border-color-dark;
            color: $white !important;
          }
  
        }
      }
    }
  }

  .contract-content {
    ul {
      li {
        &:after {
          background-color: $gray-600;
        }
      }
    }
    ol {
      li {
        &:before {
          background-color: $gray-700;
        }
      }
    }
  }  

  .summation-holder {
    background-color: rgba(255, 255, 255, 0.03);
  }

  .billing-info-major-indicator {
    background-color: $tradewind;
  }
  
  .billing-info-minor-indicator {
    background-color: $jaggedice;
  }

}
