// Modal dark styles of the app 

@include color-mode(dark) {
  .csv-main {
    border-color: $border-color-dark;
  }
  .modal-backdrop {
    background-color: $mineshaft;
  }
}
