.body-text {
  display: block;
  margin: 10px auto;
  font-size: 22px;
}

.body-text-small {
  display: block;
}

.key-input {
  width: 40%;
}
