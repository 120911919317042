// Table styles of the app 

.table-holder {
  overflow-x: auto;
  @include media-breakpoint-down(md) {
    margin-bottom: 0.625rem;
  }
  .table {
    @include media-breakpoint-down(md) {
      white-space: nowrap;
    }
  }
}

.table {
  font-size: 0.875rem;
  .actions {
    @include position(absolute,0,0,0,null);
    @include d-flex(center,null,null,null);
    transition: $transition-base;
    padding: 0.125rem 1.25rem 0.125rem 3.75rem;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    max-height: 0;
  }
  .form-check {
    margin-bottom: 0;
    min-height: inherit;
  }
  thead {
    th {
      font-weight: 500;
    }
  }
  th, td {
    background-color: transparent;
  }
  tbody {
    tr {
      position: relative;
      &.disabled {
        background-color: $porcelain;
      }
      &:hover {
        .actions {
          background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 20%);
          opacity: 1;
          visibility: visible;
          max-height: 4000px;
        }
      }
    }
  }
}

.sites-data-head {
  background-color: $gray-100;
}

.table-footer {
  @include d-flex(center,flex-end,null,null);
  position: relative;
  z-index: 1;
}

.sorter {
  display: inline-block;
  position: relative;
  padding-right: 15px;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -6px;
    border: 5px solid;
    border-width: 0 4px 5px;
    border-color: transparent transparent $dovegray;
    display: none;
  }

  &:after {
    margin-top: 1px;
    border-width: 5px 4px 0;
    border-color: $dovegray transparent transparent;
  }

  &.asc {
    &:before {
      display: block;
    }
  }

  &.desc {
    &:after {
      display: block;
    }
  }
}
  