// Navbars styles of the app 

.nav {
  > li {
    margin-bottom: 0.75rem;
    > a, .nav-btn {
      @include d-flex(center,null,null,null);
      border-radius: $border-radius;
      padding: 0.75rem 1rem;
      text-decoration: none;
      font-size: 1rem;
      border: none;
      white-space: nowrap;
      font-weight: 500;
      &.active,
      &:hover {
        background-color: $white;
        box-shadow: $box-shadow;
        span {
          color: $mineshaft;
        }
        svg {
          stroke: $primary;
        }
      }
      span {
        color: $dovegray;
      }
      svg {
        margin-right: 1rem;
        stroke: $dovegray;
      }
    }
    .nav-btn {
      justify-content: space-between;
      .iconTriangle {
        stroke: none;
      }
    }
    .nav-btn {
      &.focused,
      &.active {
        .iconTriangle {
          transform: rotate(180deg);
          stroke: none;
        }
      }
      &:hover {
        .iconTriangle {
          stroke: none;
        }
      }
    }
    .nav-btn {
      &.focused {
        background-color: rgba($color: #000000, $alpha: 0.02);
        span {
          color: $mineshaft;
        }
        svg {
          stroke: $primary;
        }
      }
    }
    ul {
      @extend %listreset;
      padding: 10px 0; 
      li {
        margin: 0.313rem 0;
        padding-left: 2.5rem;
        a {
          position: relative;
          text-decoration: none;
          color: $dovegray;
          padding: 0.625rem 1rem;
          display: block;
          font-size: 0.875rem;
          white-space: nowrap;
          border-radius: $border-radius;
          font-weight: 500;
          &:hover {
            color: $mineshaft;
            background-color: rgba($color: #000000, $alpha: 0.04);
          }
          &.active {
            color: $mineshaft;
            font-weight: 500;
            background-color: rgba($color: #000000, $alpha: 0.04);
            &:after { 
              background-color: $primary;
            }
          }
        }
      }
    }
  }
  .iconTriangle {
    fill: $mineshaft;
    @include size(8px);
    transition: $transition-base;
  }
}

.additional-nav {
  @include d-flex(center,null,null,null);
}

.user-block {
  margin-left: 1rem;
  @include media-breakpoint-up(xl) {
    margin-left: 1.25rem;
  }
  @include media-breakpoint-up(xxl) {
    margin-left: 2.5rem;
  }
}

.header {
  .navbar {
    padding: 0.625rem 0.938rem;
    border-bottom: 1px solid $border-color;
    @include media-breakpoint-up(md) {
      padding: 1rem 1.875rem;
    }
    @include media-breakpoint-up(xl) {
      padding: 1.563rem 3.125rem;
    }
    .nav-link {
      &:after {
        display: none;
      }
      .img-profile {
        border-radius: 100%;
        overflow: hidden;
        border: 1px solid $border-color;
        box-shadow: $box-shadow;
        @include size(2.625rem, 2.625rem);
        @include media-breakpoint-up(xl) {
          @include size(3.125rem, 3.125rem);
        }
        img {
          @include size(100%, 100%);
          overflow: hidden;
          object-fit: cover;
        }
      }
    }
  }
}

.user-email {
  color: $gray-600;
}

.user-info {
  padding: 0.75rem 0 0;
  font-size: 0.875rem;
}

.user-drop {
  padding: 0 1.25rem;
  min-width: 245px;
  ul {
    @extend %listreset;
    li {
      font-size: 0.875rem;
      margin: 0.313rem 0;
      a {
        border-radius: $border-radius;
        padding: 0.5rem 0.625rem;
      }
      svg {
        margin-right: 1rem;
      }
    }
  }
}

.switch-form {
  padding: 0 0.438rem;
  .form-check-input {
    margin-right: 0.875rem;
  }
  .form-check-label {
    font-size: 0.875rem;
  }
}

.no-notification {
  padding: 1rem;
  font-size: 0.75rem;
  white-space: nowrap;
}

.header {
  position: relative;
  z-index: 3;
}

#edly-navbar {
  @include media-breakpoint-down(lg) {
    @include position(absolute,100%,0,null,0);
    text-align: center;
    background-color: $white;
    box-shadow: $box-shadow;
    padding: 1.875rem 0.938rem;
  }
}

.navbar-nav {
  .nav-link {
    margin: 0 0.313rem;
    font-weight: 500;
    @include media-breakpoint-down(xxl) {
      font-size: 0.875rem;
    }
    @include media-breakpoint-down(xl) {
      font-size: 0.75rem;
      margin: 0.125rem;
      padding: 0.313rem;
    }
    @include media-breakpoint-down(lg) {
      font-size: 1rem;
      margin: 0.125rem;
      padding: 0.313rem;
    }
    &:hover {
      color: $secondary;
    }
  }
}

.nav-link {
  &:hover {
    cursor: pointer;
  }
}

.nav-tabs {
  font-weight: 600;
  .nav-item {
    margin-bottom: 0;
    margin-right: 0.313rem;
    font-weight: 500;
  }
}

.dropdown-menu {
  border-color: $border-color;
  box-shadow: $box-shadow;
}
