// Tooltip styles of the app 

.tool-tip {
  position: relative;
  display: inline-block;

  svg {
    @include size(1.25rem, 1.25rem);
  }

  .modal & {
    margin-left: 0.25rem;
  }

  .fa-question-circle {
    color: $mineshaft;
  }

  .tip-content {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    padding: 0.25rem 0.5rem 0.313rem;
    width: 9.375rem;
    font-size: 0.875rem;
    margin-left: -4.5rem;
    margin-top: 0.5rem;
    background: $black;
    border: 1px solid $black;
    transition: all 0.3s ease;
    border-radius: $border-radius;
    visibility: hidden;
    color: $white;
    opacity: 0;

    .tip-content-item {
      display: block;
      position: relative;
      padding-left: 0.75rem;
      word-wrap: break-word;

      &:before {
        content: '\2022';
        position: absolute;
        left: 0;
        top: -1px;
      }
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -0.25rem;
      border-style: solid;
      border-color: transparent transparent $black;;
      border-width: 0 0.5rem 0.5rem;
    }

    &:after {
      margin-left: -0.125;
      border-width: 0 0.375rem 0.375rem;
      border-color: transparent transparent $black;
    }
  }

  &:hover {
    cursor: default;

    .tip-content {
      visibility: visible;
      margin-top: 0.188rem;
      opacity: 1;
    }
  }
}
