// helpers and mixins

// list reset
%listreset {
	margin: 0;
	padding: 0;
	list-style: none;
}

// List @include list();

@mixin list() {
	margin: 0;
	padding: 0;
	list-style: none;
}

// size @include size();

@mixin size($width, $height: $width) {
	width: $width;
	height: $height;
}

// Placeholder @include placeholder(red);

@mixin placeholder($color) {
	&:-moz-placeholder {
		color: $color;
	}

	&::-webkit-input-placeholder {
		color: $color;
	}

	&:-ms-input-placeholder {
		color: $color;
	}
}

// position, @include(absolute,0,0,0,0);

@mixin position($position : $position, $top : $top, $right: $top, $bottom: $top, $left: $right) {
	position: $position;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

// clearfix , @include clearfix();

@mixin clearfix() {

	&:before,
	&:after {
		content: '';
		display: table;
	}

	&:after {
		clear: both;
	}
}

// display flex , @include d-flex();
@mixin d-flex($align: $align, $justify: $justify, $direction: $direction, $flow: $flow) {
	display: flex;
	align-items: $align;
	justify-content: $justify;
	flex-direction: $direction;
	flex-flow: $flow;
}

@mixin d-inline-flex($align: $align, $justify: $justify, $direction: $direction, $flow: $flow) {
	display: inline-flex;
	align-items: $align;
	justify-content: $justify;
	flex-direction: $direction;
	flex-flow: $flow;
}

@mixin multicolor-gradient (
	$start-color:$start-color , $start-percent:$start-percent, 
	$mid-color:$mid-color, 		$color-stop:$color-stop, 
	$mid-color-2:$mid-color-2,  $color-stop-2:$color-stop-2, 
	$end-color:$end-color,      $end-percent:$end-percent) {

	background: $start-color;
	background: -moz-linear-gradient(top, $start-color, $mid-color $color-stop, $mid-color-2 $color-stop-2, $end-color $end-percent);
	background: -webkit-gradient(linear, left top, left bottom, color-stop($start-percent, $start-color), color-stop($color-stop, $mid-color), color-stop($color-stop-2, $mid-color-2), color-stop($end-percent, $end-color));
	background: -webkit-linear-gradient(top, $start-color $start-percent, $mid-color $color-stop, $mid-color-2 $color-stop-2, $end-color $end-percent);
	background: -o-linear-gradient(top, $start-color $start-percent, $mid-color $color-stop, $mid-color-2 $color-stop-2, $end-color $end-percent);
	background: -ms-linear-gradient(top, $start-color $start-percent, $mid-color $color-stop, $mid-color-2 $color-stop-2, $end-color $end-percent);
	background: linear-gradient(to bottom, $start-color $start-percent, $mid-color $color-stop, $mid-color-2 $color-stop-2, $end-color $end-percent);
}
