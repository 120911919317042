// Pagination dark styles of the app 

@include color-mode(dark) {
  .pagination {
    li {
      &.active {
        a {
          color: $black;
          background-color: $secondary-dark;
          border-color: $secondary-dark;
          &:hover {
            color: $black;
            background-color: $secondary-dark;
            border-color: $secondary-dark;
          }
        }
      }
  
      &.disabled  {
        a {
          color: $gray-600;
        }
      }
      a {
        color: $white;
        &:hover {
          color: $secondary-dark;
          background-color: $codgray;
          border-color: transparent;
        }
      }
    }
  }
}
