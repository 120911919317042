// Footer styles of the app 

.footer {
  padding-left: 0.938rem;
  padding-right: 0.938rem;
  @include media-breakpoint-up(md) {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  @include media-breakpoint-up(xl) {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
  .container {
    @include d-flex(center,start,null,null);
  }
}
