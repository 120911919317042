// Slider styles of the app 

.slick-arrow {
  stroke: $mineshaft;
  @include size(1.875rem, 1.875rem);
  &.slick-disabled {
    opacity: 0;
  }
}

.slick-prev {
  left: -1.25rem;
}

.slick-next {
  right: -1.25rem;
}

.slick-slide {
  > div,
  .card {
    height: 100%;
  }
}

.imagePreview {
  .slick-slide {
    img {
      width: 100%;
    }
  }
}

.theme-preview-modal {
  .slick-prev {
    left: -1.05rem;
  }
  
  .slick-next {
    right: -1.05rem;
  }
}

