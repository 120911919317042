// Pager styles of the app 

.pagination {
  @include d-inline-flex(center,null,null,null);
  @extend %listreset;
  overflow-x: auto;
  @include media-breakpoint-down(md) {
    padding-bottom: 1.063rem;
  }
 
  li {
    margin: 0 0.125rem;
    &.active a {
      z-index: 3;
      color: $white;
      background-color: $secondary;
      border-color: $secondary;
    }

    &.disabled a {
      color: $gray-600;
      pointer-events: none;
      cursor: auto;
    }
  }

  a {
    @include d-flex(center,center,null,null);
    height: 30px;
    min-width: 30px;
    position: relative;
    padding: 0.188rem;
    line-height: normal;
    color: $mineshaft;
    text-decoration: none;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    font-weight: 500;
   
    &:hover {
      z-index: 2;
      color: $secondary;
      text-decoration: none;
      background-color: $gray-100;
      border-color: $primary;
    }

    &:focus {
      z-index: 3;
      outline: none;
      box-shadow: $box-shadow;
    }
  }

}
