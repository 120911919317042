// Buttons styles of the app 

.btn-icon {
  @include size(44px);
  @include d-inline-flex(center,center,null,null);
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.50);
  border: 1px solid $white;
  box-shadow: $box-shadow;
  &:hover,
  &:focus,
  &:active {
    background: rgba(255, 255, 255, 1);
    box-shadow: $box-shadow;
  }
  &:hover {
    cursor: pointer;
  }
  svg {
    color: $mineshaft;
  }
  .nav-link {
    display: block;
    @include size(100%);
    @include d-flex(center,center,null,null);
  }
  .help-group {
    @extend %listreset;
    li {
      a {
        &.nav-link {
          @include d-flex(center,start,null,null);
          padding: 0.625rem 1rem;
          font-size: 0.875rem;
          .img-holder {
            img {
              max-width: 20px;
            }
          }
        }
        .img-holder {
          margin-right: 1rem;
          border-radius: 100%;
          padding: 0.313rem;
          background-color: $white;
        }
      }
    }
  }
}

.alert-link {
  &:hover {
    text-decoration: none;
  }
}

.btn {
  text-transform: capitalize;
  font-weight: 500;
}

.btn-sm {
  svg {
    @include size(1rem, auto);
  }
}

.btn-link {
  border-bottom: 1px solid $secondary;
  text-decoration: none;
  border-radius: 0;
}

